import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, IconButton, Stack } from "@mui/material";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import { Link as RouterLink } from "react-router-dom";

const Billing = () => {
  const columns = [
    { Header: "transaction", accessor: "transaction", width: "10%", align: "left" },
    { Header: "expense", accessor: "expense", width: "10%", align: "left" },
    { Header: "date", accessor: "date", align: "center" },
    { Header: "paymentMode", accessor: "paymentMode", align: "center" },
    { Header: "details", accessor: "details", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = [
    {
      transaction: "9d3a4a0b",
      expense: 500,
      date: "2024-01-01",
      paymentMode: "GPay",
      details: "Grocery Shopping",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "a1f8b7c9",
      expense: 1200,
      date: "2024-01-03",
      paymentMode: "Paytm",
      details: "Electricity Bill",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "be9c3e2d",
      expense: 300,
      date: "2024-01-05",
      paymentMode: "Bank Transfer",
      details: "Office Supplies",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "d17f22e4",
      expense: 700,
      date: "2024-01-07",
      paymentMode: "UPI",
      details: "Restaurant Dinner",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "ee2a84b6",
      expense: 2000,
      date: "2024-01-09",
      paymentMode: "ATM",
      details: "Home Rent",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "f932dcb1",
      expense: 450,
      date: "2024-01-11",
      paymentMode: "Check",
      details: "Water Bill",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "3d4e9a0f",
      expense: 800,
      date: "2024-01-13",
      paymentMode: "Paytm",
      details: "Internet Subscription",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "42e7dcb3",
      expense: 1000,
      date: "2024-01-15",
      paymentMode: "GPay",
      details: "Fuel",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "5261f84a",
      expense: 250,
      date: "2024-01-17",
      paymentMode: "UPI",
      details: "Office Snacks",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "64a8c13d",
      expense: 3500,
      date: "2024-01-19",
      paymentMode: "Bank Transfer",
      details: "Credit Card Payment",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "721efcb8",
      expense: 400,
      date: "2024-01-21",
      paymentMode: "ATM",
      details: "Coffee Shop",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "8437a8d5",
      expense: 600,
      date: "2024-01-23",
      paymentMode: "Paytm",
      details: "Gym Subscription",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "9b5de7f2",
      expense: 1800,
      date: "2024-01-25",
      paymentMode: "Check",
      details: "Home Maintenance",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "aa734c91",
      expense: 120,
      date: "2024-01-27",
      paymentMode: "UPI",
      details: "Parking Fee",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "b6c92fe8",
      expense: 2250,
      date: "2024-01-29",
      paymentMode: "Bank Transfer",
      details: "Medical Bill",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "c23b19d7",
      expense: 320,
      date: "2024-01-31",
      paymentMode: "GPay",
      details: "Mobile Recharge",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "d6ea4b12",
      expense: 900,
      date: "2024-02-02",
      paymentMode: "UPI",
      details: "Gadgets Repair",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "e4725c39",
      expense: 450,
      date: "2024-02-04",
      paymentMode: "Bank Transfer",
      details: "Laundry",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "f8c7d4a5",
      expense: 2100,
      date: "2024-02-06",
      paymentMode: "ATM",
      details: "Car Insurance",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "0246bfac",
      expense: 380,
      date: "2024-02-08",
      paymentMode: "GPay",
      details: "Stationery",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "134fd839",
      expense: 700,
      date: "2024-02-10",
      paymentMode: "Check",
      details: "Furniture Purchase",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "26b3e1d4",
      expense: 1500,
      date: "2024-02-12",
      paymentMode: "UPI",
      details: "Office Supplies",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "37f9c93b",
      expense: 1000,
      date: "2024-02-14",
      paymentMode: "Paytm",
      details: "Car Fuel",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "452a718e",
      expense: 5000,
      date: "2024-02-16",
      paymentMode: "Bank Transfer",
      details: "Laptop Purchase",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "56f8a1c2",
      expense: 230,
      date: "2024-02-18",
      paymentMode: "ATM",
      details: "Taxi Fare",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "67ea8bd3",
      expense: 600,
      date: "2024-02-20",
      paymentMode: "GPay",
      details: "Subscription Fee",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "72b3f4a5",
      expense: 1300,
      date: "2024-02-22",
      paymentMode: "UPI",
      details: "Hospital Visit",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "83c27b64",
      expense: 460,
      date: "2024-02-24",
      paymentMode: "Bank Transfer",
      details: "Grocery Shopping",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "9a45e8d1",
      expense: 800,
      date: "2024-02-26",
      paymentMode: "Check",
      details: "Home Repair",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      transaction: "ac34c7f8",
      expense: 500,
      date: "2024-02-28",
      paymentMode: "UPI",
      details: "Dining Out",
      action: (
        <Stack direction='row'>
           <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ my: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button component={RouterLink} to='/expence/create' variant="contained" color="info" startIcon={<AddIcon />}>
          Add Expence
        </Button>
      </Box>

      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
      />
    </DashboardLayout>
  );
};

export default Billing;
