import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@mui/material";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ExpenceForm = () => {
  const paymentModes = ["GPay", "Paytm", "Bank Transfer", "UPI", "ATM", "Check"];

  const [formValues, setFormValues] = useState({
    transaction: uuidv4(), 
    expense: "",
    date: "",
    paymentMode: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Expense:", formValues);
    setFormValues({
      transaction: uuidv4(),
      expense: "",
      date: "",
      paymentMode: "",
      details: "",
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            },
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Transaction ID"
            name="transaction"
            value={formValues.transaction}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            label="Expense (₹)"
            name="expense"
            type="number"
            value={formValues.expense}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Date"
            name="date"
            type="date"
            value={formValues.date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Payment Mode</InputLabel>
            <Select name="paymentMode" value={formValues.paymentMode} onChange={handleChange}>
              {paymentModes.map((mode) => (
                <MenuItem key={mode} value={mode}>
                  {mode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TextField
          multiline
          rows={4}
          label="Expense Details"
          name="details"
          value={formValues.details}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Box display={"flex"} justifyContent="flex-end" gap={1} mt={3}>
          <MDButton
            variant="contained"
            color="inheri"
             component={RouterLink} to='/expence'
          >
            Cencal
          </MDButton>
          <MDButton
            variant="contained"
            color="success"
          >
            Add Expense
          </MDButton>
        </Box>
      </Paper>
    </DashboardLayout>
  );
};

export default ExpenceForm;
